import React from 'react';

function CGU() {
  return (
    <div id="cgu">
      <h1>Conditions Générales d'Utilisation</h1>
    </div>
  );
}

export default CGU;