import React from 'react';

function Header() {
  return (
    <div id="header">
      Header
    </div>
  );
};

export default Header;