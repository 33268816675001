import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './components/Home';
import Header from './components/Header';
// import Footer from './components/Footer';
import CGU from './components/CGU';

function App() {
  return (
    <BrowserRouter id="app">
      <Header />
      <Switch>
        <Route path="/cgu" component={CGU} />
        <Route path="/" component={Home}/>
      </Switch>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

export default App;
